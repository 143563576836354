import React, { useState } from "react";
import {
  Button,
  Offcanvas,
  Form,
  Tooltip,
  OverlayTrigger,
  Modal,
} from "react-bootstrap";
import "./Float.css";

import axios from 'axios'
import Swal from "sweetalert2";
import { BaseLink } from "../../config/ApiLink";

import PhoneInput from "react-phone-input-2";


export default function ContactPopup() {
  const [show, setShow] = useState(false);
 const [name,setName] = useState();
 const [email,setEmail] = useState();
 const [message,setMessage] = useState();
 const [phonenumber,setphonenumber] = useState();



  const handleClose = () => {
    // console.log("Close clicked");
    setShow(!show);
  };
  const handleShow = () => {


    setShow(!show);
  };

  const tooltip = <Tooltip id="tooltip-top">Chat with us</Tooltip>;


  const submitQuery = async (e) => {
    e.preventDefault();
    // const { name, email, phonenumber } = userRes;
    try {
      // Send a POST request with the user data
      const response = await axios.post(`${BaseLink}/submitrespons`, {
        name,
        email,
        phonenumber,
        message,
  
      });

      // If successful, show a SweetAlert confirmation
      Swal.fire({
        title: "Success!",
        text: "Your form has been submitted successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });

      setName(' ')
      setEmail(' ')
      setMessage(' ')
      setphonenumber(' ')

      setShow(false)
      

    } catch (error) {


      console.error("There was an error submitting the form:", error);

      // Show an error alert
      Swal.fire({
        title: "Error!",
        text: "There was an error submitting your form. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });


      setName(' ')
      setEmail(' ')
      setMessage(' ')
      setphonenumber(' ')

      setShow(false)


    }
  };


  


  return (
    <>
      <div className="it-about-style-3">
        <button
          variant="primary"
          className="floating-button"
          onClick={handleShow}
        >
          <span className="ripple"></span>
          <i className="bi bi-chat-dots"></i>
          <span className="it-about-thumb-shape"></span>
        </button>
      </div>

      <div className={`popup-box ${show ? 'open' : 'close'}`}>
        <div className="form-container">
          <div className="logo-container">Leave a Message</div>

           <button className="close-button" onClick={handleClose}>
            &times;
          </button> 

          <form className="form" onSubmit={submitQuery}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter your name"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="phonenumber">Phone number</label>
              {/* <input
                type="number"
                id="phonenumber"
                name="phonenumber"
                value={phonenumber}
                onChange={(e) => setphonenumber(e.target.value)}
                placeholder="Enter your Phone Number"
                required
              /> */}

<PhoneInput
    country={"in"}
    value={phonenumber}
    onChange={(value) => setphonenumber(value)}
    inputProps={{
      name: "phone",
      required: true,
      className: "form-control padding-left-10", // Keep this for the actual input field
      placeholder: "Enter your phone number",
    }}
    enableSearch={true}
    inputClass="w-100 border-dark" // Apply custom styles for the input field
  />

{/* <PhoneInput
    country={"in"}
    value={phoneNumber || userById?.phone_number}
    onChange={(value) => setphoneNumber(value)}
    inputProps={{
      name: "phone",
      required: true,
      className: "form-control", // Keep this for the actual input field
      placeholder: "Enter your phone number",
    }}
    enableSearch={true}
    inputClass="w-100 border-dark" // Apply custom styles for the input field
  /> */}
            </div>

            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea placeholder="Enter your message"
               value={message}
               onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>

            <button className="btn btn-primary my-2" type="submit">
              Send Email
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
